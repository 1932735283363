<template>
    <div>
        <loadingScreen :isLoading="isLoading"/>
        <page-header @filter="setModalFilter" filter></page-header>
        <list-component :items="this.items.data" @open="open($event)" :haveDetails="true">
            <template v-slot:principal-info="{items}">
                <div class="list-primary hide-text">{{ items.iten.name }}</div>
                <div class="list-primary hide-text d-inline-flex">
                    <div :class="items.iten.status">{{ stats(items.iten.status) }}</div>
                    <div class="signature-quantity">{{ quantityOfSignatures(items.iten) }}</div>
                </div>
            </template>
            <template v-slot:date="items">
                <div style="text-align: center">
                    <label>{{ t('DOCUMENTS.DEADLINE') }}:</label>
                    <div>{{ formatDate(items.iten.deadline_at) }}</div>
                </div>
            </template>
            <template v-slot:dropdown-options="items">
                <div v-if="items.iten.status === null || items.iten.status === 'running'"
                     class="dropdown-item pointer text-white"
                     data-target="#customModalTwo" data-toggle="modal"
                     @click="showAlert(items.iten.id, items.iten.access_token)">
                    <i class="icon-cancel mr-2 font-15"/>{{ t('DOCUMENTS.CANCEL') }}
                </div>
                <div class="dropdown-item pointer text-white" data-target="#customModalTwo" data-toggle="modal"
                     @click="showDocument(items.iten)">
                    <i class="icon-eye1 mr-2 font-15"/>{{ t('DOCUMENTS.VIEW_DOC_ORIGINAL') }}
                </div>
                <div v-if="items.iten.status === 'closed'"
                     class="dropdown-item pointer text-white"
                     data-target="#customModalTwo" data-toggle="modal"
                     @click="showDocumentSigned(items.iten)">
                    <i class="icon-eye mr-2 font-15"/>{{ t('DOCUMENTS.VIEW_DOC_ALREADY_SIGN') }}
                </div>
            </template>
            <template v-slot:details-itens="items">
                <div class="form-group mt-2" v-for="signer in items.iten.signers" v-bind:key="signer.id">
                    <ul>
                        <li>
                            <div v-if="signer.signed_at === null" class="whats pointer mt-3"
                                 @click="sendWhatsapp(signer)">
                                <a :data-flow="'left'" :data-tooltip="'Solicitação de assinatura via whatsapp'">
                                    <img src="/img/whatsapp.png">
                                </a>
                            </div>
                            <div class="list-primary hide-text" style="display: inline-block">
                <span :data-tooltip=stats(signer.status_of_signature) data-flow="bottom">
                <div id="statusSigner" :class="selectClass(signer.status_of_signature)"></div>
                  </span>
                                <div class="name-signer">{{ signer.name }}</div>
                                <div class="seller" id="sing_as"> {{ stats(signer.sign_as) }}</div>

                            </div>
                            <div v-if="signer.signed_at !== null" class="date">
                                <label>Data da Assinatura:</label>
                                <div>{{ formatDate(signer.signed_at) }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
        </list-component>
        <div class="card" v-if="noHave">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('DOCUMENTS.MSG.NONE_DOCUMENT_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <modalComponent :title-modal="titleModal" modal-large>
            <template #title>{{ titleModal }}</template>
            <filterComponent v-if="modalChoose === 1" @filtering="filtering" @clearFilter="clearFilter">
            </filterComponent>
        </modalComponent>
        <pagination-component v-if="items && items.data && items.data[0] && needPagination" :items="items" :to="'/all'"
                              @changePage="index(filter, $event)"></pagination-component>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from "vue-toastification";
import 'vue-advanced-cropper/dist/style.css';
import PaginationComponent from "@/components/layouts/PaginationComponent";
import Helper from '@/services/helper';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';
import Documents from "@/services/Documents";
import ListComponent from '@/components/layouts/listComponent';
import moment from "moment";
import PageHeader from '@/components/layouts/pageHeader';
import Signers from "../../services/Signers";
import modalComponent from '../../components/layouts/modalComponent';
import filterComponent from './filterComponent';

export default {
    name: 'IndexDocument',

    components: {
        LoadingScreen,
        PaginationComponent,
        ListComponent,
        PageHeader,
        filterComponent,
        modalComponent,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },

    data() {
        return {
            filters: {},
            modalChoose: null,
            titleModal: '',
            term: null,
            items: {},
            isLoading: null,
            noHave: false,
            needPagination: false,
            filter: "",
        }
    },

    mounted() {
        this.index();
    },

    methods: {
        clearFilter() {
            document.getElementById('closeX')?.click();
            this.filters = {};
            this.index();
        },
        filtering(filter) {
            document.getElementById('closeX')?.click();
            this.filters = filter;
            this.index();
        },
        setModalFilter() {
            this.modalChoose = 1;
            this.titleModal = 'Filtrar';
        },
        search(e) {
            this.term = e;
            this.index();
        },
        index(filter = '', page = 1) {
            this.$store.commit('changeLoading', true)
            Documents.index(filter, page, this.term, this.filters).then((resp) => {
                this.items = resp.data;

                if (resp.data.data.length === 0) {
                    this.noHave = true;
                } else {
                    this.noHave = false;
                }

                if (!(resp.data.validations) && resp.data.data.length >= 9) {
                    this.needPagination = true;
                }
                this.$store.commit('changeLoading', false)
            })
        },

        sendWhatsapp(signer) {
            signer.company_name = localStorage.getItem('company_name');
            this.$swal.fire({
                title: this.t('DOCUMENTS.MSG.ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    Signers.whatsapp(signer).then((resp) => {
                        this.isLoading = false;
                        this.toast.success(resp.data.message);
                    }).catch((err) => {
                        this.isLoading = false;
                        this.toast.error(err.response.data.message);
                    })
                }
            });
        },

        quantityOfSignatures(item) {
            return Helper.signaturesQuantity(item);
        },

        stats(word) {
            return Helper.translate(word);
        },

        selectClass(status) {
            return Helper.selectClass(status);
        },

        open(client) {
            client.open = !client.open ? true : false;
        },

        formatDate(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY H:mm')
            }
            return "";
        },

        showDocument(document) {
            Documents.show(document.id).then((resp) => {
                if (resp.data.validations) return;
                window.open(resp.data.original_file_url, '_blank');
            })
        },

        showDocumentSigned(document) {
            Documents.show(document.id).then((resp) => {
                if (resp.data.validations) return;
                window.open(resp.data.signed_file_url, '_blank');
            })
        },

        showAlert(id, accessToken) {
            this.$swal.fire({
                title: this.t('DOCUMENTS.MSG.ARE_SURE_WANT_TO_DELETE_THIS_DOCUMENT'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Excluir`,
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true)
                    return Documents.cancel(id, accessToken).then((resp) => {
                        if (!(resp.data.validations)) {
                            this.toast.success(this.t('DOCUMENTS.MESSAGES.SUCCESS_CANCEL_DOCUMENT'));
                            this.$store.commit('changeLoading', false);
                        }
                        this.index('running')
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.toast.error(err.response.data.validations.errors[0]);
                    })
                }
            });
        },
    }
}


</script>

<style lang="less">

.teste {
    width: 6px;
    position: center;
    height: 4px;
    box-shadow: #3a424c;
    text-align: center;
    border-radius: 50%;
}

.date {
    display: inline-block;
    margin-left: 45%;
}

.whats {
    display: inline-block;
    margin-left: 45%;
    float: right;
    font-size: 15px;
}

.signature-quantity {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 3px;
    color: #3a424c;
}

.seller {
    margin-left: 24px;
    font-size: 12px;
    color: #5f5f5f;
}

.name-signer {
    display: inline-block;
    width: 500px;
    margin-top: -3px;
    position: relative;
    margin-left: 23px;
}

.signer-canceled {
    display: inline-block;
    background-color: #ba0e0e;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-signed {
    display: inline-block;
    background-color: #3AB40D;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-running {
    display: inline-block;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    background-color: #E75528;
}

.canceled {
    background-color: #ba0e0e;
    width: 80px;
    color: #EBF1EB;
    font-size: 11px;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.expired {
    background-color: #262b31;
    width: 80px;
    color: #EBF1EB;
    font-size: 11px;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.running {
    background-color: #E75528;
    width: 80px;
    font-size: 11px;
    color: #EBF1EB;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.closed {
    background-color: #0e5e4e;
    width: 80px;
    font-size: 11px;
    border-radius: 1em;
    color: #EBF1EB;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

[data-tooltip] {
    position: absolute;
    cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
    line-height: 1;
    font-size: .9em;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
}

[data-tooltip]:before {
    content: "";
    border: 5px solid transparent;
    z-index: 100;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 8px;
    border-radius: 3px;
    background: #2B2525;
    color: #F5F5F5;
    z-index: 99;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #2B2525;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
    bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before, [tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, -4px);
    transform: translate(-50%, -4px);
}

[data-tooltip][data-flow="bottom"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #0F0738;
}

[data-tooltip][data-flow="bottom"]::after {
    top: calc(100% + 5px);
}

[data-tooltip][data-flow="bottom"]::before, [data-tooltip][data-flow="bottom"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, 8px);
    transform: translate(-50%, 8px);
}

[data-tooltip][data-flow="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #0F0738;
    left: calc(0em - 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #0F0738;
    right: calc(0em - 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip][data-flow="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip=""]::after, [data-tooltip=""]::before {
    display: none !important;
}
</style>
